import { defineComponent, PropType } from 'vue'
import './index.scss'
type TitleLevel = 1 | 2 | 3 | 4 | 5 | 6
export default defineComponent({
  name: 'GfrTitle',
  props: {
    level: {
      type: Number as PropType<TitleLevel>,
      default: 1
    },
    placement: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'center'
    }
  },
  setup(props, { slots }) {
    return () => {
      return h(
        `h${props.level}`,
        { class: `gfr-title gfr-title-${props.level} gfr-title-${props.placement}` },
        { default: () => slots.default?.() }
      )
    }
  }
})
